import React, { useEffect, useState } from 'react';
import './download.scss';
import playstore from './../assests/webp/play_store.webp';
import appstore from './../assests/webp/app_store.webp';
import { isIOS, isAndroid, isDesktop } from 'react-device-detect';

function Download() {

    const [details, setDetails] = useState({});

    useEffect(() => {
        fetch(
            "https://get.geojs.io/v1/ip/country.json"
        )
            .then(response => response.json())
            .then(data => setDetails(data.name));
    }, []);

    return (
        <>
            <div className='downloadApp mt-2 mt-lg-0'>
                {
                    details === 'Philippines' ?
                        <>
                            {
                                isAndroid ?
                                    <a href="https://play.google.com/store/apps/details?id=com.clash.club" target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <div className="appStoreSectionD d-flex align-items-center justify-content-center" >
                                            <div>
                                                <img src={playstore} alt="play store" />
                                                <img src={appstore} alt="app store" className='ms-1' />
                                            </div>
                                            <label className="mb-0 ms-3 ms-md-2 ms-lg-2 ms-xl-2 text-white">Download Now!</label>
                                        </div>
                                    </a> :
                                    ''
                            }
                            {
                                isIOS ?
                                    <a href="https://apps.apple.com/app/clash-skill-games/id1631335355" target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <div className="appStoreSectionD d-flex align-items-center justify-content-center">
                                            <div>
                                                <img src={playstore} alt="play store" />
                                                <img src={appstore} alt="app store" className='ms-1' />
                                            </div>
                                            <label className="mb-0 ms-3 ms-md-2 ms-lg-2 ms-xl-2 text-white">Download Now!</label>
                                        </div>
                                    </a> :
                                    ''
                            }
                            {
                                isDesktop ?
                                    <>
                                        <div data-bs-toggle="modal" data-bs-target="#staticBackdropLink">
                                            <div className="appStoreSectionD d-flex align-items-center justify-content-center">
                                                <div>
                                                    <img src={playstore} alt="play store" />
                                                    <img src={appstore} alt="app store" className='ms-1' />
                                                </div>
                                                <label className="mb-0 ms-3 ms-md-2 ms-lg-2 ms-xl-2 text-white">Download Now!</label>
                                            </div>
                                        </div>
                                    </> : ''
                            }
                        </> :
                        <>
                            <div className="appStoreSectionD d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#staticBackdropSubone">
                                <div>
                                    <img src={playstore} alt="play store" />
                                    <img src={appstore} alt="app store" className='ms-1' />
                                </div>
                                <label className="mb-0 ms-3 ms-md-2 ms-lg-2 ms-xl-2 text-white" style={{marginTop: "2px"}}>Download Now!</label>
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default Download