import React from 'react'
import './footer.scss'
// import twitter from './../assests/webp/twitter.webp'
// import discord from './../assests/webp/discord.webp'
// import youtube from './../assests/webp/youtube.webp'
import { NavLink } from 'react-router-dom'
// import { LazyLoadImage } from 'react-lazy-load-image-component'

function Footer() {
    return (
        <footer className='py-3 py-lg-4 d-none d-md-block'>
            <div className="container d-flex justify-content-between flex-column flex-md-row align-items-center">
                <div className='quickLinks d-flex justify-content-center justify-content-md-start flex-wrap mb-2 mb-md-0'>
                    {/* <NavLink to="/clashtoken" className='me-4 mb-2 mb-md-0'>
                        Clash Tokens
                    </NavLink> */}
                    <NavLink to="/faq" className='me-4 mb-2 mb-md-0'>
                        FAQs
                    </NavLink>
                    <NavLink to="/terms-of-use" className='me-4 me-md-4'>
                        Terms Of Use
                    </NavLink>
                    <NavLink to="/privacy-policy" className='me-0'>
                        Privacy Policy
                    </NavLink>
                </div>
                {/* <div>
                    <a href="https://twitter.com/clash_platform" rel="noopener noreferrer" target="_blank">
                        <LazyLoadImage src={twitter} alt="twitter" loading="lazy" />
                    </a>
                    <a href="https://discord.com/invite/FNdqWWTha7" rel="noopener noreferrer" target="_blank">
                        <LazyLoadImage src={discord} alt="discord" loading="lazy" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCj-VXZ1GTGxRF6S3eNrHVyw" rel="noopener noreferrer" target="_blank">
                        <LazyLoadImage src={youtube} alt="youtube" loading="lazy" />
                    </a>
                </div> */}
            </div>
        </footer>
    )
}

export default Footer