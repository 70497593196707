import React from 'react'
// import cash from './../assests/webp/Clash.webp'
import faq from './../assests/webp/faq.webp'
import privacypolicy from './../assests/webp/Privacy-Policy.webp'
import termsconditions from './../assests/webp/Terms-Conditions.webp'
import logo from './../assests/logo.png'
import { NavLink } from 'react-router-dom'
import './sidemenu.scss'

function SideMenu() {
    return (
        <div className="sideMenu">
            <div className="offcanvas-header">
                <img className="logo" src={logo} alt="logo" style={{ marginLeft: '-4.3px' }} />
                <svg xmlns="http://www.w3.org/2000/svg" data-bs-dismiss="offcanvas" aria-label="Close" width="22" height="22" stroke='#8645f8' fill="#8645f8" style={{ marginTop: '-7px' }} className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
            </div>
            <div className="offcanvas-body">
                <div className="d-flex flex-column navHeader">
                    {/* <NavLink to="/clashtoken">
                        <div className="d-flex align-items-centers menuLink py-3" data-bs-dismiss="offcanvas">
                            <div className="imgBg">
                                <img className="mbl-icon-cls" src={cash} alt="clash tokens" />
                            </div>
                            <p className='mb-0'>Clash Tokens</p>
                        </div>
                    </NavLink> */}
                    <NavLink to="/faq">
                        <div className="d-flex align-items-centers menuLink py-3" data-bs-dismiss="offcanvas">
                            <div className="imgBg">
                                <img className="mbl-icon-cls" src={faq} alt="clash tokens" />
                            </div>
                            <p className='mb-0'>FAQs</p>
                        </div>
                    </NavLink>
                    <NavLink to="/privacy-policy">
                        <div className="d-flex align-items-centers menuLink py-3" data-bs-dismiss="offcanvas">
                            <div className="imgBg">
                                <img className="mbl-icon-cls" src={privacypolicy} alt="clash tokens" />
                            </div>
                            <p className='mb-0'>Privacy Policy</p>
                        </div>
                    </NavLink>
                    <NavLink to="/terms-of-use">
                        <div className="d-flex align-items-centers menuLink py-3" data-bs-dismiss="offcanvas">
                            <div className="imgBg">
                                <img className="mbl-icon-cls" src={termsconditions} alt="clash tokens" />
                            </div>
                            <p className='mb-0'>Terms Of Use</p>
                        </div>
                    </NavLink>
                </div>
                <div className="text-left headerFooter mt-4">
                    <label className="mb-0">Reach Us</label><br />
                    <a href="mailto:info@getclash.com"> info@getclash.com</a>
                </div>
            </div>
        </div>
    )
}

export default SideMenu
