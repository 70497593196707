import React, { useEffect, useState } from 'react'
import Footer from '../footer/footer'
import './policies.scss'
import logo from './../assests/logo.png'
import { HashLink as Link } from 'react-router-hash-link';
import menu from './../assests/menu.png'
import SideMenu from '../sidemenu/sidemenu'
import Download from '../download/download';
import Policypopup from './policyPopup'


function Clashtokens() {
    // useEffect(() => {
    // window.scrollTo(0, 0)
    // });

    const [scrollHeader, setscrollHeader] = useState(false);
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setscrollHeader(window.pageYOffset >= 100)
            );
        }
    }, []);


    const headerOthers = () => {
        return (
            <>
                <div className="container headerSectionPolicies px-lg-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <Link smooth to="/">
                                <img className="logo" src={logo} alt="logo" />
                            </Link>
                            <div className="d-none d-md-block">
                                <div className='d-flex navLinks'>
                                    <Link smooth to="/#about"><p className='mb-1 ms-5'>About</p></Link>
                                    <Link smooth to="/#featuredgames"><p className='mb-1 ms-5'>Featured Games</p></Link>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center litepaperMenu">
                            <div className='d-none d-lg-block'>
                                {
                                    scrollHeader ?
                                        <Download /> : ''
                                }
                            </div>
                            {/* <a href="https://litepaper.clash.club/clash-litepaper/" target='_blank' rel="noopener noreferrer" className="litePaper">Litepaper</a> */}
                            {/* <img src={menu} alt="menu icon" className='hamburgerMenu d-block d-md-none' data-bs-toggle="modal" data-bs-target="#staticBackdropSideMenu" width="26" /> */}
                            <img src={menu} alt="menu icon" className='hamburgerMenu d-block d-md-none' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" width="26" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <div className="policies pt-md-4">
            {
                scrollHeader ?
                    <header className='afterDownloadButtonHomeOthers' style={{ position: 'sticky', top: '0', paddingTop: '0px !important' }}>
                        {headerOthers()}
                        <div className="d-block d-lg-none">
                            <Download />
                        </div>
                    </header> : ''
            }
            <section className=''>
                {headerOthers()}
            </section>

            <Policypopup />
            {/* <div className="modal mobileSideMenu" id="staticBackdropSideMenu" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog m-0 modal-dialog-centered" role="document"> */}
            <div className="mobileSideMenu offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <SideMenu />
            </div>
            {/* <Menu /> */}
            <div className="container mb-3 mt-5 mt-md-2">
                <h1 className='mainheading mt-5'>How to get Clash Tokens</h1>
                <p className='lastUpdate mb-5'><b>Last Updated:</b> September 10, 2022</p>
                <p className='pText'>Clash Tokens (CLH) are used for playing skill games against relevant opponents.</p>
                <p className='pText'>CLH is on Solana Blockchain.</p>
                <h4 className='subHeading mt-3'>There are multiple ways to get CLH tokens.</h4>
                <div>
                    <ul>
                        <li className='mb-2'>Buy CLH tokens | On ramping
                            <ol>
                                <li>Swap other currency to Clash on any of the available on ramping solutions.</li>
                            </ol>
                        </li>
                        <li className='mb-2'>Win in 1v1 battles
                            <ol>
                                <li>By winning 1v1 battles, players can earn CLH tokens.</li>
                            </ol>
                        </li>
                        <li className='mb-2'>Rewards
                            <ol>
                                <li>Players playing 1v1 &amp; tournaments can get additional CLH tokens everyday</li>
                                <li>Complete every checkpoint in each of the following categories &amp; get access to amazing rewards daily.
                                    <ul>
                                        <li>Number of games</li>
                                        <li>Time Spent</li>
                                        <li>Amount Wagered</li>
                                        <li>Win Streak</li>
                                        <li>Daily Streak</li>
                                        <li>Staking Reward</li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li className='mb-2'>Win in tournaments
                            <ol><li> By winning in free &amp; paid tournaments, players can get CLH tokens.</li>
                                <li>All the players who are on top of the leaderboard are eligible to get CLH tokens.</li>
                            </ol>
                        </li>
                        <li>Refer &amp; Invite friends
                            <ol><li>Players can refer &amp; invite their friends on Clash to get CLH tokens.</li>
                                <li>Players get rewarded for every valid transaction their direct referrals or indirect referrals make on Clash.</li>
                            </ol>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="mt-5">
                <Footer />
            </div>
        </div >
    )
}

export default Clashtokens