import React from 'react'
import './policies.scss'

function CFaqs() {
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // });

    return (
        <div className="policies">
            <div className="container my-4">
                <div className="mb-4">
                    <h1 className="mainheading ps-3 ">FAQs</h1>
                    {/* <p className='pText'><b>Last Updated</b>: September 10, 2022</p> */}
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                What is Clash and how does it work?
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Clash is a gaming platform where players can play their favourite skill based games and earn crypto rewards. The players can play 1v1 battles & free tournaments in all the games that are on Clash platform.</div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExampleTwo">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                What are the games offered and how to play them?
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExampleTwo">
                            <div className="accordion-body">Currently, we are offering 12 games. We will soon be adding more games. Each game is a proper skill game where two players compete against each other to get a better score.</div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExampleThree">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                How to download and install the app?
                            </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExampleThree">
                            <div className="accordion-body">To download the app, visit our website Clash. club. The app is available on the Play Store & the Appstore as well, ‘Clash - Win Cash' is the name of the app. Download and start playing your favourite casual games to earn crypto rewards.</div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExampleFour">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                How to log in for the first time?
                            </button>
                        </h2>
                        <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExampleFour">
                            <div className="accordion-body">After downloading the app, you can login through your Facebook, Apple or Google account. On the sign up screen, all the three options would be provided.</div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExampleFive">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                How can I edit/change my account information?
                            </button>
                        </h2>
                        <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExampleFive">
                            <div className="accordion-body">You can click on the 'Profile' icon to change your name and profile avatar.</div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExampleSix">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                Why am I unable to play games from my Country/County/state?
                            </button>
                        </h2>
                        <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExampleSix">
                            <div className="accordion-body">Due to the applicable laws in respective countries/states/counties, users are not allowed to play games involving cryptocurrencies. So, if you belong to any of those countries/states/counties, you wouldn't be able to play games on Clash.</div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExampleSeven">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                Can I have multiple accounts?
                            </button>
                        </h2>
                        <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExampleSeven">
                            <div className="accordion-body">Since your account is linked with Facebook, Apple or Google, you can only have one account.</div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExampleEight">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingEight">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                Can I temporarily delink/delete my account?
                            </button>
                        </h2>
                        <div id="flush-collapseEight" className="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExampleEight">
                            <div className="accordion-body">Yes, you can delink/delete your account. If the account is deleted, all the data concerning the account will be lost. Delink option can be used when you want to delink temporarily but when you link back the account, all the data related to the account will be back. Delinking an account can be done through profile while deleting an account can be done through hamburger button on the main screen.</div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExampleNine">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingNine">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                What is a wallet?
                            </button>
                        </h2>
                        <div id="flush-collapseNine" className="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExampleNine">
                            <div className="accordion-body">A blockchain wallet is a digital wallet that allows users to store, manage & trade their cryptocurrencies. This is an E-wallet and users can manage their balances of all the different crypto assets.</div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExampleTen">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                What are Custodial Wallet and Non-Custodial Wallet?
                            </button>
                        </h2>
                        <div id="flush-collapseTen" className="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExampleTen">
                            <div className="accordion-body">
                                <p>
                                    <b>Non Custodial Wallet: </b>
                                    In a non-custodial wallet, you will have sole control of your private keys, no third party is involved and you are solely responsible for protecting your private keys and funds in your wallet.
                                </p>
                                <p>
                                    <b>Custodial Wallet: </b>
                                    For all new users who are new to the crypto space, it’s very likely that the first time you purchase crypto, the purchased crypto will end up in a wallet like an exchange wallet or any other source of purchase wallet. In this case, the exchange or source of purchase is your custodian, which holds your keys and is responsible for securing your wallet and the funds in it.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample11">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading11">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                                Is Clash Wallet a custodial or Non-custodial wallet?
                            </button>
                        </h2>
                        <div id="flush-collapse11" className="accordion-collapse collapse" aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample11">
                            <div className="accordion-body">
                                To make it easy for new users, we start it with a custodial wallet till your funds reach a point and then after we guide you to create your non-custodial wallet and transfer all the funds that you have in your custodial wallet to the non-custodial wallet which you would be creating.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading12">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                                Can we Import wallets which are already available?
                            </button>
                        </h2>
                        <div id="flush-collapse12" className="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample12">
                            <div className="accordion-body">
                                Yes, you can import a wallet if you already have one.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample13">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading13">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
                                Can we have Multiple Wallets?
                            </button>
                        </h2>
                        <div id="flush-collapse13" className="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample13">
                            <div className="accordion-body">
                                Yes, You can have multiple wallets but there will be primary wallets where the fund will be wagered and rewards will be sent to the primary wallet which is selected by the user..
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample14">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading14">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
                                What happens if I get disconnected in the middle of the game?
                            </button>
                        </h2>
                        <div id="flush-collapse14" className="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample14">
                            <div className="accordion-body">
                                If there are any internet issues at your end, we try to connect you back to the game but in case of a weak internet connection at your end, after we try for a certain time, the opponent will be declared as winner.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample15">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading15">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15">
                                If there are any technical issues while playing the game, do I get my money back?
                            </button>
                        </h2>
                        <div id="flush-collapse15" className="accordion-collapse collapse" aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample15">
                            <div className="accordion-body">
                                If there is any technical issue in the game while playing, our team will look into it and will refund the amount that you lost in the game. But if there is no amount lost then no refund will be given.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample16">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading16">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16">
                                Can I play Clash games on my PC/laptop?
                            </button>
                        </h2>
                        <div id="flush-collapse16" className="accordion-collapse collapse" aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample16">
                            <div className="accordion-body">
                                Currently, Clash is available only on iOS and Android.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample17">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading17">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse17" aria-expanded="false" aria-controls="flush-collapse17">
                                I have not received my signup bonus.
                            </button>
                        </h2>
                        <div id="flush-collapse17" className="accordion-collapse collapse" aria-labelledby="flush-heading17" data-bs-parent="#accordionFlushExample17">
                            <div className="accordion-body">
                                Once you complete your signup, you will be given a few clash tokens and once you play the 3 games you will be given more clash tokens.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample18">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading18">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse18" aria-expanded="false" aria-controls="flush-collapse18">
                                I have not received my referral reward.
                            </button>
                        </h2>
                        <div id="flush-collapse18" className="accordion-collapse collapse" aria-labelledby="flush-heading18" data-bs-parent="#accordionFlushExample18">
                            <div className="accordion-body">
                                You will receive a referral reward once the referred player starts playing the game. You will receive the referral bonus based on whether the referred person is a direct referral or an indirect referral.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample19">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading19">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse19" aria-expanded="false" aria-controls="flush-collapse19">
                                Where can I see my signup bonus?
                            </button>
                        </h2>
                        <div id="flush-collapse19" className="accordion-collapse collapse" aria-labelledby="flush-heading19" data-bs-parent="#accordionFlushExample19">
                            <div className="accordion-body">
                                The signup bonus will be credited to your wallet and it is 100% usable.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample20">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading20">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse20" aria-expanded="false" aria-controls="flush-collapse20">
                                I have not received my signup bonus.
                            </button>
                        </h2>
                        <div id="flush-collapse20" className="accordion-collapse collapse" aria-labelledby="flush-heading20" data-bs-parent="#accordionFlushExample20">
                            <div className="accordion-body">
                                Once you complete your signup, you will be given a few clash tokens and once you play the 3 games you will be given more clash tokens.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample21">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading21">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse21" aria-expanded="false" aria-controls="flush-collapse21">
                                How to withdraw my winnings?
                            </button>
                        </h2>
                        <div id="flush-collapse21" className="accordion-collapse collapse" aria-labelledby="flush-heading21" data-bs-parent="#accordionFlushExample21">
                            <div className="accordion-body">
                                You can withdraw your winnings by providing relevant details on the Withdrawals page.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample22">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading22">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse22" aria-expanded="false" aria-controls="flush-collapse22">
                                I have not received my signup bonus.
                            </button>
                        </h2>
                        <div id="flush-collapse22" className="accordion-collapse collapse" aria-labelledby="flush-heading22" data-bs-parent="#accordionFlushExample22">
                            <div className="accordion-body">
                                Once you complete your signup, you will be given a few clash tokens and once you play the 3 games you will be given more clash tokens.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample23">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading23">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse23" aria-expanded="false" aria-controls="flush-collapse23">
                                Can I withdraw the bonus cash earned?
                            </button>
                        </h2>
                        <div id="flush-collapse23" className="accordion-collapse collapse" aria-labelledby="flush-heading23" data-bs-parent="#accordionFlushExample23">
                            <div className="accordion-body">
                                No, you cannot withdraw the bonus. However, you can use this to play more games.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample24">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading24">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse24" aria-expanded="false" aria-controls="flush-collapse24">
                                Do I need to pay transaction charges?
                            </button>
                        </h2>
                        <div id="flush-collapse24" className="accordion-collapse collapse" aria-labelledby="flush-heading24" data-bs-parent="#accordionFlushExample24">
                            <div className="accordion-body">
                                Transaction charges are on us. So we will take care of all transactions.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample25">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading25">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse25" aria-expanded="false" aria-controls="flush-collapse25">
                                Can I change my name on the app?
                            </button>
                        </h2>
                        <div id="flush-collapse25" className="accordion-collapse collapse" aria-labelledby="flush-heading25" data-bs-parent="#accordionFlushExample25">
                            <div className="accordion-body">
                                Yes, you change your name by editing your name in the account.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample26">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading26">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse26" aria-expanded="false" aria-controls="flush-collapse26">
                                What is Cheating?
                            </button>
                        </h2>
                        <div id="flush-collapse26" className="accordion-collapse collapse" aria-labelledby="flush-heading26" data-bs-parent="#accordionFlushExample26">
                            <div className="accordion-body">
                                Hacking, using multiple accounts or any other way of manipulation. Hacking and using multiple accounts or teaming up with players to raise scores is considered cheating and is not tolerated on this platform. If found guilty of cheating, you will be permanently blocked from playing games and withdrawing cash. You will also lose your winnings from the games where cheating has been done. Further, any balances on your wallets will be forfeited.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample27">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading27">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse27" aria-expanded="false" aria-controls="flush-collapse27">
                                Is my personal information secure?
                            </button>
                        </h2>
                        <div id="flush-collapse27" className="accordion-collapse collapse" aria-labelledby="flush-heading27" data-bs-parent="#accordionFlushExample27">
                            <div className="accordion-body">
                                We use best-in-class data security practices and tools to ensure the security and privacy of user data. All our systems are regularly tested using automated and manual processes for security.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample28">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading28">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse28" aria-expanded="false" aria-controls="flush-collapse28">
                                What is blockchain?
                            </button>
                        </h2>
                        <div id="flush-collapse28" className="accordion-collapse collapse" aria-labelledby="flush-heading28" data-bs-parent="#accordionFlushExample28">
                            <div className="accordion-body">
                                Blockchain is a system in which the information is recorded in a way that makes it difficult or impossible to change or cheat or hack the system. It is a digital ledger of all the transactions that are distributed & maintained across several systems which are linked in a peer-to-peer network.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample30">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading30">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse30" aria-expanded="false" aria-controls="flush-collapse30">
                                Why blockchain for skill gaming?
                            </button>
                        </h2>
                        <div id="flush-collapse30" className="accordion-collapse collapse" aria-labelledby="flush-heading30" data-bs-parent="#accordionFlushExample30">
                            <div className="accordion-body">
                                <ul>
                                    <li>Highly incentivized community</li>
                                    <li>Millions of downloads with higher LTV</li>
                                    <li>No bots policy & Elo rating-based matchmaking</li>
                                    <li>Decentralization leads to higher engagement</li>
                                    <li>Liquidity for the existing user base</li>
                                    <li>Less or no fraud.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample31">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading31">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse31" aria-expanded="false" aria-controls="flush-collapse31">
                                What is a transaction fee & why am I not being charged for this?
                            </button>
                        </h2>
                        <div id="flush-collapse31" className="accordion-collapse collapse" aria-labelledby="flush-heading31" data-bs-parent="#accordionFlushExample31">
                            <div className="accordion-body">
                                A transaction fee is the transaction fee paid to miners on a blockchain network in order to get the user’s transaction to be included in the chain. The dynamic fee value keeps changing depending on various factors.<br />

                                On Clash, we do not charge the user any sort of dynamic fee but we would take care of the dynamic fee for every user’s transaction. We want the Clash platform users to have seamless transactions on the wallet without worrying about the extra dynamic fees.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample32">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading32">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse32" aria-expanded="false" aria-controls="flush-collapse32">
                                What is USDC?
                            </button>
                        </h2>
                        <div id="flush-collapse32" className="accordion-collapse collapse" aria-labelledby="flush-heading32" data-bs-parent="#accordionFlushExample32">
                            <div className="accordion-body">
                                USDC (USD Coin) is a cryptocurrency that is backed by fiat money. It is a stablecoin. USDC is a digital currency that is fully backed by USD.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample33">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading33">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse33" aria-expanded="false" aria-controls="flush-collapse33">
                                What is On/Off Ramping?
                            </button>
                        </h2>
                        <div id="flush-collapse33" className="accordion-collapse collapse" aria-labelledby="flush-heading33" data-bs-parent="#accordionFlushExample33">
                            <div className="accordion-body">
                                On-Ramping is when you use regular or fiat money to buy any crypto coin/token. In On-Ramping, the money enters the decentralized blockchain ecosystem.<br />

                                Off-Ramping is when you cash out of crypto where you convert your crypto coins back into fiat money
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample34">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading34">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse34" aria-expanded="false" aria-controls="flush-collapse34">
                                What is Swapping & How does it work?
                            </button>
                        </h2>
                        <div id="flush-collapse34" className="accordion-collapse collapse" aria-labelledby="flush-heading34" data-bs-parent="#accordionFlushExample34">
                            <div className="accordion-body">
                                Swapping is when players convert one cryptocurrency into another. On Clash, swapping is very easy. As of now, players can go to their wallet and click on the custom swap. Players can swap CLH for USDC or USDC for CLH. Players can check the details of the current fair price & swap fees, etc. Some predefined options to convert tokens from USDC & USDC to tokens are also provided.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample35">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading35">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse35" aria-expanded="false" aria-controls="flush-collapse35">
                                What is a liquidity pool?
                            </button>
                        </h2>
                        <div id="flush-collapse35" className="accordion-collapse collapse" aria-labelledby="flush-heading35" data-bs-parent="#accordionFlushExample35">
                            <div className="accordion-body">
                                A liquidity pool is a digital supply of cryptocurrency that is secured by a smart contract. The liquidity pool allows players to do transactions quickly. Liquidity pools are a cluster of tokens with pre-determined weights. Total weight is how much its value accounts for the total value within the pool.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample36">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading36">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse36" aria-expanded="false" aria-controls="flush-collapse36">
                                What is the commission?
                            </button>
                        </h2>
                        <div id="flush-collapse36" className="accordion-collapse collapse" aria-labelledby="flush-heading36" data-bs-parent="#accordionFlushExample36">
                            <div className="accordion-body">
                                The commission is the platform fee that is charged by the Clash platform on the pool of entry fees that are collected before giving the reward to the winners.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample37">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading37">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse37" aria-expanded="false" aria-controls="flush-collapse37">
                                What is CLH?
                            </button>
                        </h2>
                        <div id="flush-collapse37" className="accordion-collapse collapse" aria-labelledby="flush-heading37" data-bs-parent="#accordionFlushExample37">
                            <div className="accordion-body">
                                CLH (Clash Token) is a utility token that is used for playing skill games on Clash. CLH is specifically used for utility & also, to reward players in Clash which is a casual skill gaming platform.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample38">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading38">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse38" aria-expanded="false" aria-controls="flush-collapse38">
                                Where do I check my Clash Activity?
                            </button>
                        </h2>
                        <div id="flush-collapse38" className="accordion-collapse collapse" aria-labelledby="flush-heading38" data-bs-parent="#accordionFlushExample38">
                            <div className="accordion-body">
                                The activity screen shows all the details on your ongoing games & your past activities. In the past activity, all the activities of 1v1, tournaments, etc will be displayed. The ongoing activity shows the total number of ongoing activities & you would be given the option to expand them to see all the activities along with the game name, battle name, score & time left for the game. <br />
                                The player can tap on any activity to get more information on the activity.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample39">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading39">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse39" aria-expanded="false" aria-controls="flush-collapse39">
                                My Payment failed. What should I do?
                            </button>
                        </h2>
                        <div id="flush-collapse39" className="accordion-collapse collapse" aria-labelledby="flush-heading39" data-bs-parent="#accordionFlushExample39">
                            <div className="accordion-body">
                                As it is a blockchain game, payments might fail a few times. In such scenarios, please go to the activity & try to pay for the payment of failed games. In case the game did not start, you can pay & play the game again to submit the score. In case the game has already been completed, once payment is done, the results will be displayed.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample40">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading40">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse40" aria-expanded="false" aria-controls="flush-collapse40">
                                My Payment is in progress. What should I do?
                            </button>
                        </h2>
                        <div id="flush-collapse40" className="accordion-collapse collapse" aria-labelledby="flush-heading40" data-bs-parent="#accordionFlushExample40">
                            <div className="accordion-body">
                                In case the payment is in progress, please wait for some time & check the payment status in activity.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample41">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading41">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse41" aria-expanded="false" aria-controls="flush-collapse41">
                                My Match is Cancelled. Why is that?
                            </button>
                        </h2>
                        <div id="flush-collapse41" className="accordion-collapse collapse" aria-labelledby="flush-heading41" data-bs-parent="#accordionFlushExample41">
                            <div className="accordion-body">
                                In case of any payment failures or if no opponent is found in 7 days, matches are canceled & the entry fee is refunded back to the players.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample63">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading63">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse63" aria-expanded="false" aria-controls="flush-collapse63">
                                How does Matchmaking work?
                            </button>
                        </h2>
                        <div id="flush-collapse63" className="accordion-collapse collapse" aria-labelledby="flush-heading63" data-bs-parent="#accordionFlushExample63">
                            <div className="accordion-body">
                                Using historical match data, an AI model has been constructed through neural networks. It focuses on optimizing the predicted score margin as a percentage of the winner's score. This process takes various factors into consideration, including Elo scores, average scores, and the margin of victory or defeat for both players. It then uses this information to predict the expected margin for current match requests.
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className='subHeading mt-4 mb-3'>Rewards FAQs</h2>
                <div className="accordion accordion-flush" id="accordionFlushExample42">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading42">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse42" aria-expanded="false" aria-controls="flush-collapse42">
                                What are the different types of rewards I can get?
                            </button>
                        </h2>
                        <div id="flush-collapse42" className="accordion-collapse collapse" aria-labelledby="flush-heading42" data-bs-parent="#accordionFlushExample42">
                            <div className="accordion-body">
                                Clash is very rewarding to players. There are multiple ways to get rewards on Clash.
                                <ul>
                                    <li>Number of games</li>
                                    <li>Time Spent</li>
                                    <li>Amount Wagered</li>
                                    <li>Win Streak</li>
                                    <li>Daily Streak</li>
                                    <li>Staking Reward</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample43">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading43">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse43" aria-expanded="false" aria-controls="flush-collapse43">
                                How do I get the rewards?
                            </button>
                        </h2>
                        <div id="flush-collapse43" className="accordion-collapse collapse" aria-labelledby="flush-heading43" data-bs-parent="#accordionFlushExample43">
                            <div className="accordion-body">
                                Active engagement on clash is a way to get these rewards. When you play the games on Clash, every activity will be considered & if the checkpoint of any particular reward is crossed, you can get that reward. Playing multiple games on a single day within 24 hours can get you multiple rewards. So, keep the activity going & unlock some amazing rewards.<br />
                                <b>Note -</b>  Usually, these rewards have a reset timer. The timer is clearly mentioned in the earn section on Clash app.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample44">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading44">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse44" aria-expanded="false" aria-controls="flush-collapse44">
                                How do I claim these rewards?
                            </button>
                        </h2>
                        <div id="flush-collapse44" className="accordion-collapse collapse" aria-labelledby="flush-heading44" data-bs-parent="#accordionFlushExample44">
                            <div className="accordion-body">
                                You can claim the reward once the checkpoint is completed. In case the reward is not claimed, when the timer resets, all these rewards will be automatically claimed & will be added to the rewards section.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample45">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading45">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse45" aria-expanded="false" aria-controls="flush-collapse45">
                                What are rewards?
                            </button>
                        </h2>
                        <div id="flush-collapse45" className="accordion-collapse collapse" aria-labelledby="flush-heading45" data-bs-parent="#accordionFlushExample45">
                            <div className="accordion-body">
                                When you claim the reward from the earn screen, the rewards would be added to My Rewards screen. On the rewards screen, scratch cards will be visible. You can scratch the cards & unlock the rewards accordingly.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample46">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading46">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse46" aria-expanded="false" aria-controls="flush-collapse46">
                                When can I scratch the cards?
                            </button>
                        </h2>
                        <div id="flush-collapse46" className="accordion-collapse collapse" aria-labelledby="flush-heading46" data-bs-parent="#accordionFlushExample46">
                            <div className="accordion-body">
                                All the claimed scratch cards will be available for scratch the next day. You cannot scratch the card when it is in locked state. Once the card is available, you can scratch it to get rewards. Also, please note that these scratch cards will be expired after 3 days once it is unlocked. So, make sure you scratch the available cards before they expire.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample47">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading47">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse47" aria-expanded="false" aria-controls="flush-collapse47">
                                How is the time spent calculated?
                            </button>
                        </h2>
                        <div id="flush-collapse47" className="accordion-collapse collapse" aria-labelledby="flush-heading47" data-bs-parent="#accordionFlushExample47">
                            <div className="accordion-body">
                                The time spent is calculated based on how much time you are spending playing a game.
                                <b>Note -</b> This is calculated only once for a free tournament. We consider the best timer in case of multiple gameplays.
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className='subHeading mt-4 mb-3'>Game Related FAQs</h2>
                <div className="accordion accordion-flush" id="accordionFlushExample48">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading48">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse48" aria-expanded="false" aria-controls="flush-collapse48">
                                Archery Game FAQs
                            </button>
                        </h2>
                        <div id="flush-collapse48" className="accordion-collapse collapse" aria-labelledby="flush-heading48" data-bs-parent="#accordionFlushExample48">
                            <div className="accordion-body">
                                <p>What is the highest & lowest score a player can achieve?</p>
                                <ul>
                                    <li>The highest score with one shot is 10 & lowest is 1.</li>
                                </ul>
                                <p>What is wind in the game & how does it affect the game</p>
                                <ul>
                                    <li>Wind in the game plays an important role as it can directly affect the direction in which the arrows are shot.</li>
                                </ul>
                                <p>
                                    How is a winner decided?
                                </p>
                                <ul>
                                    <li>The player who has the highest score at the end of all rounds will be the winner.</li>
                                </ul>
                                <p>What is the target?</p>
                                <ul>
                                    <li>The target will be a circular board which is kept at a distance of a few meters. Players will be shooting the circular board.</li>
                                </ul>
                                <p>How to hit the target?</p>
                                <ul>
                                    <li>The player needs to hold the screen to aim & then release the finger to shoot the arrow.</li>
                                </ul>

                                <p>What is Bullseye?</p>
                                <ul>
                                    <li>Bullseye is the center of the circular board which is the target & the player would get 10 points.</li>
                                </ul>
                                <p>How are points decided?</p>
                                <ul>
                                    <li>Hitting the center of the target will give the player 10 points, and the points will be reduced the farther the arrow is hitting the target from the center.
                                    </li>
                                    <li>Missing the target will be 0 score.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample49">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading49">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse49" aria-expanded="false" aria-controls="flush-collapse49">
                                Fruit Slice Game FAQs
                            </button>
                        </h2>
                        <div id="flush-collapse49" className="accordion-collapse collapse" aria-labelledby="flush-heading49" data-bs-parent="#accordionFlushExample49">
                            <div className="accordion-body">
                                <p>How many cards will the player get in each round?</p>
                                <ul>
                                    <li>8 cards will be dealt with the player in each round, 2 cards per turn.</li>
                                </ul>
                                <p>How many community cards are there in a round?</p>
                                <ul>
                                    <li>5 Community cards are placed on the top of the screen in each round.</li>
                                </ul>
                                <p>What is meant by “dealt cards”?</p>
                                <ul>
                                    <li>Cards that are given to the player in each round are called dealt cards and the process is called dealing cards.</li>
                                </ul>
                                <p>What are community cards?</p>
                                <ul>
                                    <li>These cards are placed on the top part of the screen and are used to make poker hands with each Player's hand.
                                    </li>
                                </ul>
                                <p>What are the Player's hands?</p>
                                <ul>
                                    <li>Player’s Hands are made of 2 cards from the cards that are dealt with the player and are used to make poker hands with community cards.</li>
                                </ul>
                                <p>What are poker Poker hands?</p>
                                <ul>
                                    <li>Poker Hands are combinations of cards which reward players points based on their Ranks.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample50">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading50">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse50" aria-expanded="false" aria-controls="flush-collapse50">
                                Fruit Slice FAQs
                            </button>
                        </h2>
                        <div id="flush-collapse50" className="accordion-collapse collapse" aria-labelledby="flush-heading50" data-bs-parent="#accordionFlushExample50">
                            <div className="accordion-body">
                                <p>Special Fruits hold power-ups and can be activated by slashing them:</p>
                                <p>There are 4 Power-up Fruits</p>
                                {/* <ul> */}
                                <p>Freeze Fruit: </p>
                                <ul>
                                    <li>Slashing the Freeze fruit will activate Freeze mode.</li>
                                    <li>During freeze mode, the fruit moves slowly.</li>
                                </ul>
                                <p> Magnet Fruit: </p>
                                <ul>
                                    <li>Slashing the Magnet fruit will activate the Magnetic effect around the slash.
                                    </li>
                                    <li>As long as the magnetic effect is active, the fruits will move towards the slash.</li>
                                </ul>
                                <p> Time Fruit: </p>
                                <ul>
                                    <li>Extra Time will be added by slashing this fruit.
                                    </li>
                                </ul>

                                <p>Double Fruit: </p>
                                <ul>
                                    <li> Points will be double for a while, after this fruit is slashed</li>
                                </ul>
                                {/* </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample51">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading51">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse51" aria-expanded="false" aria-controls="flush-collapse51">
                                Speed 21 Game FAQs
                            </button>
                        </h2>
                        <div id="flush-collapse51" className="accordion-collapse collapse" aria-labelledby="flush-heading51" data-bs-parent="#accordionFlushExample51">
                            <div className="accordion-body">
                                <p>How to make 21 combinations? </p>
                                <ul>
                                    <li>21 combinations can be made by placing cards that add up to 21 in the same slot.</li>
                                </ul>

                                <p>How many lives does the player get in a Match?</p>
                                <ul>
                                    <li>The player will get 3 lives per match</li>
                                </ul>

                                <p>How does a player lose a life?</p>
                                <ul>
                                    <li>Life will be lost when the player makes a combination which exceeds 21</li>
                                </ul>


                                <p>What number does each card represent?</p>
                                <ul>
                                    <li>All the number cards represent their respective numbers in games, ace represents 1 and, King, Queen and Jack represent 10.</li>
                                </ul>

                                <p>What other combinations can be made? </p>

                                <p>The Player can make 5 other combinations </p>
                                <ul>
                                    <li><b>3 Sevens</b>( 175 Points ) - Creating a Combination of 21 using 3 Seven’s card. This combination will include 3 cards of seven irrespective of their Suit.
                                    </li>
                                    <li><b>Blackjack</b>( 150 points) - This combination includes an Ace card and a Jack card irrespective of their tile.
                                    </li>
                                    <li><b>Dynamite </b>( 125 Points) - This combination includes two cards which have a value of 10 and an Ace card.
                                    </li>
                                    <li><b>Three and Sixes</b> ( 150 points) - This combination will have 4 cards with each card being of a unique tile. In this combination, there will be 3 cards with the value of <b>Six</b>  and one card with the value of <b>Three.</b>
                                    </li>
                                    <li><b>5 cards</b> (100 Points)  -  If 5 cards are placed in a culumn without getting busted, then the player will get 100 points.</li>
                                </ul>
                                <p>What is a wild card?</p>

                                <ul>
                                    <li>Wild cards can make any combination into 21 irrespective of the cards in the slot when it is placed in that slot.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample52">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading52">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse52" aria-expanded="false" aria-controls="flush-collapse52">
                                Bingo Game FAQs
                            </button>
                        </h2>
                        <div id="flush-collapse52" className="accordion-collapse collapse" aria-labelledby="flush-heading52" data-bs-parent="#accordionFlushExample52">
                            <div className="accordion-body">
                                <p className="cst-active">How to daub the number?</p>
                                <ul><li>Players can daub the number by simply tapping on the number called.</li></ul>
                                <p>How does Free daub work?</p>
                                <ul><li>Players can mark off any number on the board irrespective of the number being called.</li></ul>
                                <p>How does 2x points work?</p>
                                <ul><li>For every right daub, the player gets double the score.</li></ul>
                                <p>How does Gulden Ball work?</p>
                                <ul><li>Players can pick one from 4 balls, this ball will be the next number to be called.</li></ul>
                                <p>How does Extra Time work?</p>
                                <ul><li>Extra time adds 10 more seconds to the game timer.</li></ul>
                                <p>What happens if the player fails to daub and the next number is called? </p>
                                <ul><li>Players can daub the numbers as long as it is displayed on the top.</li></ul>
                                <p>How is the winner decided?</p>
                                <ul><li>The player with the highest score will be declared winner.</li></ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample53">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading53">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse53" aria-expanded="false" aria-controls="flush-collapse53">
                                Cricket Game FAQs
                            </button>
                        </h2>
                        <div id="flush-collapse53" className="accordion-collapse collapse" aria-labelledby="flush-heading53" data-bs-parent="#accordionFlushExample53">
                            <div className="accordion-body">
                                <p>How many overs are there in total?</p>
                                <ul><li>The game is 2 overs long, the 2nd over consists of a 2x score bonus.</li></ul>

                                <p>How to hit the ball?</p>
                                <ul><li>The ball can be hit by tapping on either side of the screen. Although tapping on either side works, all bowling techniques cannot be hit using either side. In the case of a yorker, tapping on the left side may cause the player to lose a wicket, whereas timing your shot right and tapping on the right may make the ball fly off for a six.</li></ul>
                                <p>How does timing work? </p>
                                <ul><li>A well-timed shot can make the ball fly off for a six. A poorly timed shot may lead to losing your wicket or a dot ball.</li></ul>

                                <p>What happens if I tap in the wrong direction?</p>
                                <ul><li>Players will fail to connect the ball and that leads to a dot ball or players may even lose a wicket.</li></ul>

                                <p>What happens if a player loses a wicket?</p>
                                <ul><li>Losing a wicket does not mean the game is over, players lose a small amount of their score.</li></ul>

                                <p>How is the winner decided?</p>
                                <ul><li>The player with the highest score wins.</li></ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample54">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading54">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse54" aria-expanded="false" aria-controls="flush-collapse54">
                                Cube Solitaire Game FAQs:
                            </button>
                        </h2>
                        <div id="flush-collapse54" className="accordion-collapse collapse" aria-labelledby="flush-heading54" data-bs-parent="#accordionFlushExample54">
                            <div className="accordion-body">
                                The time spent is calculated based on how much time you are spending playing a game.
                                <b>Note -</b> This is calculated only once for a free tournament. We consider the best timer in case of multiple gameplays.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample55">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading55">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse55" aria-expanded="false" aria-controls="flush-collapse55">
                                WWTBAM Game FAQs:
                            </button>
                        </h2>
                        <div id="flush-collapse55" className="accordion-collapse collapse" aria-labelledby="flush-heading55" data-bs-parent="#accordionFlushExample55">
                            <div className="accordion-body">
                                <p>How much does the ladder go up for each correct answer?</p>
                                <ul>
                                    <li className="">For each correct answer, the ladder goes up by one.</li>
                                </ul>
                                <p>How much does the ladder go down for each incorrect answer?</p>
                                <ul><li>For each incorrect answer, the ladder goes down by one.</li></ul>
                                <p>What is a Streak Bonus?</p>
                                <ul>
                                    <li>Answer the questions correctly one after the other without fail for bonus points. </li>
                                    <li>For each question answered correctly in a sequence you get 10 points. </li>
                                </ul>
                                <p>What is the penalty?</p>
                                <ul><li>Wrong answers give you a negative score. </li>
                                    <li>Each wrong answer deducts ten points as a penalty.</li>
                                </ul>
                                <p>What is a Timer Bonus?</p>
                                <ul>
                                    <li>If you finish all questions before the timer runs out then you get a timer bonus for the remaining time. </li>
                                    <li>The timer bonus equals ten times the remaining timer. </li>
                                </ul>
                                <p>What are milestones?</p>
                                <ul><li>Once you reach a milestone on the ladder you cannot go down the ladder below the milestone, no matter how many questions are answered incorrectly.</li></ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample56">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading56">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse56" aria-expanded="false" aria-controls="flush-collapse56">
                                8 Ball Pool Game FAQs:
                            </button>
                        </h2>
                        <div id="flush-collapse56" className="accordion-collapse collapse" aria-labelledby="flush-heading56" data-bs-parent="#accordionFlushExample56">
                            <div className="accordion-body">
                                <p>How to aim the cue stick?</p>
                                <ul><li>Drag your finger around the cue ball to aim.</li></ul>
                                <p>How to shoot?</p>
                                <ul><li>Use the power bar at the bottom of the screen by dragging and adjusting the power and then releasing it to shoot the ball.</li></ul>
                                <p>How is Pocket Score calculated?</p>
                                <ul><li>The Pocket score is calculated by multiplying the ball number by the multiplier of the pocket.</li></ul>
                                <p>What is a Streak Bonus?</p>
                                <ul><li>Bonus score rewarded for consecutive successful shots. </li></ul>
                                <p>What is a Distance Score?</p>
                                <ul><li>Score based on Distance traveled by the ball</li></ul>
                                <p>What is the trickshot score and how is it calculated?</p>
                                <ul>
                                    <li>Trickshot is when the cue ball makes contact with several balls before pocketing a ball.</li>
                                    <li>The trickshot score is calculated by multiplying the number of contacts made by the ball by 10.</li>
                                </ul>
                                <p>What do the power-ups do?</p>
                                <ul>
                                    <li>The first power-up randomizes the pocket’s multiplier</li>
                                    <li>The second power-up undoes the last shot.</li>
                                </ul>
                                <p>How is the total score calculated?</p>
                                <ul>
                                    <li>Total Score = Pocket Score + Streak Bonus + Distance Score + Trickshot Score</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample57">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading57">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse57" aria-expanded="false" aria-controls="flush-collapse57">
                                Hunting Game FAQs
                            </button>
                        </h2>
                        <div id="flush-collapse57" className="accordion-collapse collapse" aria-labelledby="flush-heading57" data-bs-parent="#accordionFlushExample57">
                            <div className="accordion-body">
                                <p>How to aim and shoot?</p>
                                <ul>
                                    <li>Tap and hold to aim down the sight.</li>
                                    <li>Drag to aim at the deer.</li>
                                    <li>Release to shoot.</li>
                                </ul>

                                <p>How to cancel the shot?</p>
                                <ul><li>Swipe down to cancel the shot.</li></ul>

                                <p>How does the scope timer work? </p>
                                <ul>
                                    <li>Each shot has a limited amount of slow-mo time. Use it wisely.</li>
                                    <li>The scope timer can be seen around the edge of the scope.</li>
                                    <li>The shot will cancel if the timer runs out.</li>
                                </ul>

                                <p>How does the scoring system work?</p>
                                <ul><li>Your score = Distance x Accuracy </li>
                                    <li>Shooting deer without antlers results in a penalty.</li>
                                    <li>The accuracy multiplier ranges from 1x to 10x. </li>
                                    <li>Get the bonus points for hitting the bonus moving icon.</li>
                                    <li>Get a streak bonus by making consecutive successful shots.</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample58">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading58">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse58" aria-expanded="false" aria-controls="flush-collapse58">
                                Hoops FAQs:
                            </button>
                        </h2>
                        <div id="flush-collapse58" className="accordion-collapse collapse" aria-labelledby="flush-heading58" data-bs-parent="#accordionFlushExample58">
                            <div className="accordion-body">
                                <p>How to aim and shoot?</p>
                                <ul>
                                    <li>Drag the finger</li>
                                    <li>Release to shoot</li>
                                </ul>

                                <p>How to get a clean shot?</p>
                                <ul><li>When the basketball goes through the hoop cleanly without touching anything, it is considered a clean shot.</li></ul>

                                <p>How does the multiplier work?</p>
                                <ul>
                                    <li>The multiplier meter keeps moving from 1x to 5x.</li>
                                    <li>When the finger is released to shoot, the meter pointer stops.</li>
                                    <li>Based on the position of the meter, the multiplier &amp; the score is calculated.</li>
                                </ul>

                                <p>What are the different bases on the screen?</p>
                                <ul>
                                    <li>Depending on the distance from hoop, the base multiplier is calculated. </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample59">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading59">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse59" aria-expanded="false" aria-controls="flush-collapse59">
                                Dominoes FAQs:
                            </button>
                        </h2>
                        <div id="flush-collapse59" className="accordion-collapse collapse" aria-labelledby="flush-heading59" data-bs-parent="#accordionFlushExample59">
                            <div className="accordion-body">
                                <p>How long is each game? </p>
                                <ul>
                                    <li>Each game is 2 minutes long.</li>
                                </ul>

                                <p>How does the scoring work?  </p>
                                <ul><li>When a player plays a tile and the sum of open tiles is a multiple of 5, that sum is awarded as a score to the player. 10 points for 10 pips, 15 points for 15 pips.</li></ul>

                                <p>What are pips?</p>
                                <ul>
                                    <li>Each domino tile is divided into 2 parts with a line, the dots on either side are known as pip.</li>
                                </ul>

                                <p>What happens if a player has no tile to make the play?</p>
                                <ul>
                                    <li>Player has to keep drawing tiles from the boneyard until the player gets a playable hand.</li>
                                </ul>

                                <p>What is a boneyard?</p>
                                <ul>
                                    <li>The remaining dominoes after distributing to each player is a boneyard.</li>
                                </ul>

                                <p>What happens if a player gets a domino and the opponent player still has tiles?</p>
                                <ul>
                                    <li>In such cases, the opponent's sum of tiles will be awarded to the player. Know that the sum will be rounded off to the nearest multiple of 5.</li>
                                </ul>

                                <p>How is the winner decided? </p>
                                <ul>
                                    <li>FIrst player to reach 100 points is declared winner, remaining time is also added to the score if any.</li>
                                    <li>In scenarios where neither of the players can’t make 100 points, the player with the highest score is declared as winner when the timer hits zero.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample60">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading60">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse60" aria-expanded="false" aria-controls="flush-collapse60">
                                Poker FAQs:
                            </button>
                        </h2>
                        <div id="flush-collapse60" className="accordion-collapse collapse" aria-labelledby="flush-heading60" data-bs-parent="#accordionFlushExample60">
                            <div className="accordion-body">
                                <p>How many seats are there on each table? </p>
                                <ul>
                                    <li>In Battles(Cash Games) we have 5 seat tables and in Tournaments we have 9 seat tables. This can change depending on game modes and events.
                                    </li>
                                </ul>

                                <p>How does the timer work?</p>
                                <ul>
                                    <li>The timer appears on your profile picture when it’s your turn. You get 15 seconds per turn in normal tables and 10 seconds in fast tables.</li>
                                    <li>You also get a time bank shown at the bottom left corner which starts depleting when the normal timer gets over.</li>
                                </ul>

                                <p>How can I sit out in Poker?</p>
                                <ul>
                                    <li>In Poker you can sit out by checking on the “Sit out next hand” option in the settings menu.</li>
                                    <li>You will also be made to sit out if you do not take any action in the game and let the timer run out.</li>
                                </ul>

                                <p> How long can I stay in sitout?</p>
                                <ul>
                                    <li>Once you're in sit out you have 5 minutes to return to the game otherwise you will be removed from the table.</li>
                                    <li>This 5 minute timer resets every hour so use it only when necessary.</li>
                                </ul>

                                <p>How does sitting out work in Battles and Tournaments?</p>
                                <ul>
                                    <li>In Battles, once you're in sitout, no cards will be dealt and no blinds will be deducted. You can stay in sitout for 5 minutes cumulative per hour.</li>
                                    <li>In Tournaments, while in sitout, cards will be dealt and blinds will be deducted and you will automatically fold when it's your turn. You can stay in sitout for as long as you wish or till the tournament ends.</li>
                                </ul>

                                <p>Can I play on more than one table?</p>
                                <ul>
                                    <li>Yes, you can play on 3 tables simultaneously, using the multi table feature. Just click on the + icon to join a new table while playing on a table.</li>
                                </ul>

                                <p> How do I leave the table without losing blinds?</p>
                                <ul>
                                    <li>Go to settings and enable “Sit out next hand” under table settings. Now you won’t be dealt cards next hand and you can leave the table safely, in the next hand, by tapping the back button in the top left corner of the screen.
                                    </li>
                                </ul>
                                <p> Which game modes are available in Poker?</p>
                                <b className='ms-2'>Our Poker has the following game modes:</b>
                                <ul className='ms-3'>
                                    <li>Battles i.e. Cash Tables where you can bet with Tokens.</li>
                                    <li>Tournaments where you can buy entries with Tokens and play with chips. Winners get Tokens as a prize.
                                    </li>
                                </ul>
                                <p> Which variants of Poker are available?</p>
                                <b className='ms-2'>Following variants of Poker are available:</b>
                                <ul className='ms-3'>
                                    <li>No Limit Texas Hold’em (NLH)</li>
                                    <li>Pot Limit Omaha with 4,5 and 6 cards (PLO4, PLO5, PLO6)</li>
                                </ul>
                                <p> How do I buy-in on a cash table?</p>
                                <b className='ms-2'>To play on a cash table in Poker, this is what you need to do:</b>
                                <ul className='ms-3'>
                                    <li>Tap on the table with the blinds you wish to play.</li>
                                    <li>Drag to select the amount you wish to buy-in with.</li>
                                    <li>Tap on buy-in to start playing.</li>
                                </ul>
                                <p> How do I know my hand combination?</p>
                                <ul>
                                    <li>You can see your hand combination name under the community cards during gameplay.</li>
                                    <li>Use this combined with the hand ranking button in the top right hand corner to decide your action.</li>
                                </ul>
                                <p> Can I mute the sound effects in the game?</p>
                                <ul>
                                    <li>Yes, go to settings and check off “SFX” under the game settings.</li>
                                </ul>
                                <p> Can I view my last hand in the game?</p>
                                <ul>
                                    <li>Yes, to view recent history on the cash table click on the logs button.</li>
                                    <li>To view older hands click on “My Tables” from the lobby.</li>
                                </ul>
                                <p>What types of tournaments are available?</p>
                                <b className='ms-2'>The following tournaments are available right now in Poker:</b>
                                <ul className='ms-3'>
                                    <li>Freeroll : Free entry and guaranteed rewards for winners</li>
                                    <li>Guaranteed : Paid entry and guaranteed rewards for winners</li>
                                    <li>Prize Pool : Paid entry and buy-in amount is pooled for rewards.</li>
                                </ul>
                                <p> How is Rake in Poker calculated?</p>
                                <b className='ms-2'>Cash Games:</b>
                                <ul className='ms-3'>
                                    <li>In Cash Games, the poker rake is calculated as a percentage from the winning amount. </li>
                                    <li>Rake is not collected when a pot splits if there are 2 or more winners of the same pot.</li>
                                </ul>
                                <b className='ms-2'> Tournaments</b>
                                <ul className='ms-3'>
                                    <li>A fixed fee is charged according to buyin of each tourney. You can check the fee details in the tournament lobby or while registering the tourney.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample61">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading61">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse61" aria-expanded="false" aria-controls="flush-collapse61">
                                Gin Rummy FAQs:
                            </button>
                        </h2>
                        <div id="flush-collapse61" className="accordion-collapse collapse" aria-labelledby="flush-heading61" data-bs-parent="#accordionFlushExample61">
                            <div className="accordion-body">
                                <p>How do I deal the cards?</p>
                                <ul>
                                    <li>The system shuffles the deck and deals ten cards to each player, one at a time. The top card of the remaining deck is then placed face-up on the table, forming the discard pile.</li>
                                </ul>

                                <p>What is a "meld"?</p>
                                <ul>
                                    <li>A meld is a combination of cards, either a set (three or four cards of the same rank) or a sequence (three or more consecutive cards of the same suit).</li>
                                </ul>

                                <p>What are the deadwood points?</p>
                                <ul>
                                    <li>The deadwood points are the total value of unmatched cards in a player's hand. Number cards are worth their face value, face cards (Jacks, Queens, Kings) are worth 10 points each, and Aces are worth 1 point each.</li>
                                </ul>

                                <p>What are Gin and BigGin?</p>
                                <ul>
                                    <li><b>Gin:</b>  When a player successfully forms all the cards in their hand into valid melds (combinations of sets or runs). The player should have zero deadwood in their hand when they knock.</li>
                                    <li><b>Big Gin:</b> "Big Gin" occurs when a player goes "Gin" but with the additional condition that all 11 cards in their hand are used to form melds, leaving no remaining deadwood.</li>
                                </ul>

                                <p>What is Undercut?</p>
                                <ul>
                                    <li>"Undercut" refers to a situation where the player who did not initiate the knock ends up with fewer deadwood in their hand than the knocking player.</li>
                                </ul>

                                <p>What happens if the draw pile runs out?</p>
                                <ul>
                                    <li>If the draw pile runs out before a player has knocked or the game ends, the discard pile is shuffled (except for the top card) to form a new draw pile.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample62">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading62">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse62" aria-expanded="false" aria-controls="flush-collapse62">
                                Rummy FAQs:
                            </button>
                        </h2>
                        <div id="flush-collapse62" className="accordion-collapse collapse" aria-labelledby="flush-heading62" data-bs-parent="#accordionFlushExample62">
                            <div className="accordion-body">
                                <p>How do we join a table?</p>
                                <ul>
                                    <li>Players can choose a preferred table in the lobby and Buy-in the entry tokens to start playing.</li>
                                </ul>

                                <p>Can we select the Seat on a table?</p>
                                <ul>
                                    <li>The seats are allocated randomly to every player in a particular game.</li>
                                </ul>

                                <p>What is draw and discard?</p>
                                <ul>
                                    <li>Drawing is picking a card from closed or open deck during the player’s turn. When a card is drawn, an unwanted card from the player’s hand is discarded into the open deck.  Known as discarding.</li>
                                </ul>

                                <p>What are sets and sequences?</p>
                                <ul>
                                    <li><b>Sets:</b> A set is a group of three or four cards of the same rank but different suits. For example, a set could be comprised of 9 of hearts, 9 of clubs, and 9 of diamonds.</li>
                                    <li><b>Sequence:</b> A sequence is a group of three or more consecutive cards of the same suit. For example, a sequence could be composed of the 9, 8, and 10 of hearts.</li>
                                </ul>

                                <p>Can we make a set with 5 cards?</p>
                                <ul>
                                    <li>No. A set is a group of three to four cards only. Adding a 5th card to a set will make it an invalid set. </li>
                                </ul>

                                <p>How to identify if it's my turn?</p>
                                <ul>
                                    <li>When it is a player turn, player profile will be highlighted and a green turn timer will be running around the profile.</li>
                                </ul>

                                <p>What are Joker and Wild Joker cards?</p>
                                <ul>
                                    <li>A printed Joker is a card that can be used as a substitute for any other card to complete a set or a sequence. </li>
                                    <li><b>Wild Joker:</b>A specific card is selected at the beginning of the game to act as a Joker. For example, if the 9 of hearts is selected as the Joker, then all the 9s (9 of spades, 9 of clubs, and 9 of diamonds) will act as Jokers in that particular game.</li>
                                </ul>

                                <p>When can we use a Joker?</p>
                                <ul>
                                    <li>Players can use a joker card to make impure sequences and sets. Joker and wild joker cards can be used to substitute the missing cards in a set or an impure sequence.</li>
                                </ul>

                                <p>Can we pick a Joker card discarded by another player?</p>
                                <ul>
                                    <li>If a player discards a joker accidentally, no other player is allowed to pick the joker card from the open deck.</li>
                                </ul>

                                <p>What is drop and middle drop?</p>
                                <ul>
                                    <li>If the player decides not to play a game before drawing the first card, then it is a drop. If the player drops out of a game any time after picking his first card, then it is a middle drop. </li>
                                </ul>

                                <p>What is auto discard?</p>
                                <ul>
                                    <li>Player is given 30 seconds to perform his action. If the player picks the card and fails to discard the card, the last drawn card will be discarded automatically.</li>
                                </ul>

                                <p>What is an Invalid declaration?</p>
                                <ul>
                                    <li>An invalid declaration is when players believe they have formed valid sets and sequences and declare their hand. However, if the declared sets and sequences are not valid it is considered to be an invalid declaration.</li>
                                    <li>Here are some common mistakes that may result in an invalid declaration.</li>
                                    <li><b>Incomplete Sets or Sequences:</b> If a player has incomplete sets or sequences, meaning they are missing one or more cards to form a proper combination, their declaration will be invalid.</li>
                                    <li><b>Insufficient Pure Sequences:</b> Players must have at least one pure sequence in their hand to declare. A pure sequence is a sequence formed without the use of any Joker cards. If a player fails to have a valid pure sequence along with their other sets and sequences, their declaration will be invalid.</li>
                                    <li><b>Improper Usage of Jokers:</b> If a player uses Jokers inappropriately, such as using them in a pure sequence or using too many Jokers in a set, their declaration will be invalid.</li>
                                    <li><b>Wrong Placement of Cards:</b> When declaring, players need to arrange their cards properly, separating their sets and sequences for clarity. If the cards are not arranged correctly, it will be considered as an invalid declaration.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CFaqs