import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './home';
import Clashtokens from './policies/clashtokens';
import Privacypolicy from './policies/privacypolicy';
import Termsofuse from './policies/termsofuse';
import Faqs from './policies/faqs';
import CFaqs from './clashpolicies/faqs';
import CClashtokens from './clashpolicies/clashtokens';
import CPrivacypolicy from './clashpolicies/privacypolicy';
import CTermsofuse from './clashpolicies/termsofuse';
import ScrollToTop from './scrollToTop';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    AOS.init({ offset: 20 });
    AOS.refresh();
  }, []);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/clashtoken" element={<Clashtokens />} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          <Route path="/terms-of-use" element={<Termsofuse />} />
          <Route path="/faq" element={<Faqs />} />


          <Route path="/clash-faq" element={<CFaqs />} />
          <Route path="/clash-how-to-get-clh-token" element={<CClashtokens />} />
          <Route path="/clash-privacy-policy" element={<CPrivacypolicy />} />
          <Route path="/clash-terms-of-use" element={<CTermsofuse />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
