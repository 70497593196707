import React from 'react'
import './policies.scss'

function CPrivacypolicy() {
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // });
    return (
        <div className="policies">
            <div className="container my-4">
                <div className="mb-4 my-4">
                    <h1 className="mainheading">Privacy Policy</h1>
                    <p className='lastUpdate'><b>Last Updated</b>: September 10, 2022</p>
                </div>
                <div className="mb-4 mt-3">
                    <h2 className="subHeading">Applicability</h2>
                    <p className='pText'>Clash is committed to the protection of personal information. As a part of this commitment, the applicable Terms of Use and the Privacy Policy hereinbelow describes how we use personal information, and the circumstances under which we may share, retain or destroy this information. This Privacy Policy was established within the context, and applies to the users of our services (Clash Website, Clash App, Custom Websites or Apps created by Clash), features, content or applications offered by Clash, and any other websites or apps operated, hosted, or maintained by Clash. Our Privacy Policy is applicable to not only the uses of Clash website and Service, but also all interactions between you and Clash, as there is the possibility that during such interaction, we may acquire your personal information. All users, end-users, and third parties are notified that such policies are applicable only to the interaction between Clash and the individual user or the customer of Clash.</p>
                    <p className='pText'>Please read this privacy policy carefully by accessing or using the website/mobile application. You agree to be bound by the terms described herein. If you do not agree to all of these terms, please do not use the website/mobile application. Using the website/mobile application will be considered as a deemed acceptance of this policy.</p>
                </div>

                <div className="mb-4">
                    <h2 className="subHeading">Content &amp; Purpose</h2>
                    <p className='pText'>This privacy policy (“Privacy Policy”) applies to your use of the domain name <a href="https://getclash.com/">https://getclash.com/</a>, an internet-based portal, (hereinafter referred to as “We”, “Our”, “Us” or “Clash”). The domain name and the mobile application are collectively referred to as “Website”.</p>
                    <p className='pText'>Our Website provides a web-based platform that gives our restaurant business partners a single integrated interface for managing all online and in-person orders (hereinafter referred to as “Services”). The Services would be made available to such persons who have agreed to become buyers or users on the Website after obtaining due registration/accepting necessary terms and conditions, in accordance with the procedure as determined by us, from time to time (referred to as “You” or “Your” or “Yourself” or “User”, which terms shall also include persons who are accessing the Website merely as visitors).</p>
                    <p className='pText'>We have implemented reasonable security practices and procedures that are commensurate with the information assets being protected and with the nature of our business. While we try our best to provide security that is commensurate with the industry standards, because of the inherent vulnerabilities of the internet, we cannot ensure or warrant the complete security of all information that is being transmitted to us by you.</p>
                    <p className='pText'>For the purpose of providing the Services and for other purposes identified in this Privacy Policy, we will be required to collect and host certain data and information of the Users. We are committed to protecting the Personal Information (as defined below) of the Users and take all reasonable precautions for maintaining the confidentiality of the User’s Personal Information. This Privacy Policy has been designed and developed to help you understand the following:</p>
                    <ul>
                        <ol>
                            <li>The type of Personal Information (including Sensitive Personal Data or Information) that we collect from the Users.</li>
                            <li>The purpose of collection, means and modes of usage of such Personal Information by Clash.</li>
                            <li>How and to whom Clash will disclose such information.</li>
                            <li>How Clash will protect the Personal Information including Sensitive Personal Data or Information that is collected from the Users and</li>
                            <li>How Users may access and/or modify their Personal Information.</li>
                        </ol>
                    </ul>
                    <p className='pText'>This Privacy Policy shall apply to the use of the Website by all Users. Accordingly, a condition of each User’s use of and access to the Website and to the other services provided by Clash to Users is their acceptance of this Privacy policy. Any User is required to read and understand the provisions set out herein prior to submitting any Sensitive Personal Data or Information to Clash, failing which they are required to leave the Website immediately.</p>
                </div>

                <div className="mb-4">
                    <h2 className="subHeading">Personal Information</h2>
                    <p className='pText'><strong>“Personal Information”</strong> means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available with Clash, is capable of identifying the person concerned.</p>

                    <p className='pText'><strong>“Sensitive Personal Data or Information”</strong> means Personal Information of any individual relating to password; financial information such as bank account or credit card or debit card or other payment instrument details; physical, physiological and mental health condition; sexual orientation; medical records and history; biometric information; any detail relating to the above as provided to or received by Clash for processing or storage. However, any data/information relating to an individual that is freely available or accessible in public domain or furnished under any applicable legislation or any other law shall not qualify as Sensitive Personal Data or Information.</p>
                </div>

                <div className="mb-4">
                    <h2 className="subHeading">Types of Personal Information collected by Clash</h2>
                    <p className='pText'>A User may have limited access to the Website and utilize some of the Services provided by Clash without creating an account on the Website. Unregistered Users can access some of the information and details available on the Website. In order to have access to all the features and benefits on our Website, a User may be required to first create an account on our Website. As part of the registration process, we may collect the following categories of Personal Information from the Users (hereinafter collectively referred to as <strong>“User Information”</strong>):</p>

                    <ul>
                        <li>Name</li>
                        <li>User ID</li>
                        <li>Wallet address</li>
                        <li>Email address</li>
                        <li>Address (including country and ZIP/postal code)</li>
                        <li>Gender</li>
                        <li>Age</li>
                        <li>Phone Number</li>
                        <li>Password chosen by the User</li>
                        <li>Valid financial account information</li>
                        <li>Other details as the User may volunteer.</li>
                    </ul>
                    <p className='pText'>In order to avail the Services, the Users may be required to upload/share certain documents, on the Website and/or e-mail the same to Clash in accordance with the applicable Terms of Use and such documents will be stored/disclosed by Clash only in the manner specified in this Privacy Policy and the applicable Terms of Use. The term “User Information” shall also include any such documents uploaded or otherwise provided by Users. We may keep records of telephone calls received and made for making inquiries, orders, or other purposes for the purpose of administration of Services.</p>
                </div>

                <div className="mb-4">
                    <h2 className="subHeading">Internet Use &amp; Cookie Policy</h2>
                    <p className='pText'>We may also receive and/or hold information about the User’s browsing history including the URL of the site that the User visited prior to visiting the Website as well as the Internet Protocol (IP) address of each User’s computer (or the proxy server a User used to access the World Wide Web), User’s computer operating system and type of web browser the User is using as well as the name of User’s ISP. The Website uses temporary cookies to store certain data (that is not Sensitive Personal Data or Information) that is used by us for the technical administration of the Website, research and development, and for User administration.</p>
                    <ul>
                        <li>We do not knowingly collect Personal Information from children.</li>
                        <li>We may in future include other optional requests for information from the User including through user surveys in order to help us customize the Website to deliver personalized information to the User and for other purposes are mentioned herein. Such information may also be collected in the course of contests conducted by us. Any such additional Personal Information will also be processed in accordance with this Privacy Policy.</li>
                    </ul>
                </div>

                <div className="mb-4">
                    <h2 className="subHeading">Purposes for which Clash may use the Information</h2>
                    <p className='pText'>We will retain User Information only to the extent it is necessary to provide Services to the Users. The information, which we collect from you, may be utilized for various business and/or regulatory purposes including for the following purposes:</p>
                    <ul>
                        <ol>
                            <li>Registration of the User on the Website;</li>
                            <li>Processing the User’s orders/requests and provision of Services (including provision of safe Services);</li>
                            <li>Completing transactions with Users effectively and billing for the products/Services provided;</li>
                            <li>Technical administration and customization of Website;</li>
                            <li>Ensuring that the Website content is presented to the Users in an effective manner;</li>
                            <li>Delivery of personalized information and target advertisements to the User;</li>
                            <li>Improvement of Services, features and functionality of the Website;</li>
                            <li>Research and development and for User administration (including conducting user surveys);</li>
                            <li>Non-personally identifiable information, exclusively owned by Clash may be used in an aggregated or non-personally identifiable form for internal research, statistical analysis and business intelligence purposes including those for the purposes of determining the number of visitors and transactional details, and we may sell or otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates;</li>
                            <li>Dealing with requests, enquiries, complaints or disputes and other customer care related activities including those arising out of the Users’ request of the Services and all other general administrative and business purposes;</li>
                            <li>Communicate any changes in our Services or this Privacy Policy or the applicable Terms of Use to the Users; Clash reserves the right to change this Privacy Policy from time to time. The most current version of the policy will govern our use of your personal information. In the even that Clash, at its sole discretion, determines that updates to the Privacy Policy constitute a material change, we may inform you of such changes in a notice published via the Services or send an email to the email address associated with your User account. Notwithstanding the foregoing, Clash is under no obligation to notify a User regarding changes to this policy, and thus Users should periodically visit this page to review the then current Policy to which you are bound.</li>
                            <li>Verification of identity of Users and perform checks to prevent frauds; and</li>
                            <li>Investigating, enforcing, resolving disputes and applying the applicable Terms of Use and Privacy Policy, either ourselves or through third party service providers.</li>
                        </ol>
                    </ul>
                    <p className='pText'>We comply by Children’s Online Privacy Protection Act (COPPA) and do not target our Website or our products or merchandise for sale on our Website to children, and our Website does not seek to collect contact information from children under the age of 13. However, should we become aware that a child under the age of 13 has provided us with personal information; Clash will take the necessary steps to remove such information and terminate the child’s account.</p>
                    <p className='pText'>If you are under the age of 13, please do not, submit any personal information to our Website, or ask us to email you. If you are under the age of 18, you should get permission from a parent or guardian before you email our Website, contact our Website, attempt to use our Website, submit information to our Website, or ask us to email you.</p>
                </div>

                <div className="mb-4">
                    <h2 className="subHeading">Disclosure and Transfer of User’s Personal Information</h2>
                    <p className='pText'>We may need to disclose/transfer User’s Personal Information to the following third parties for the purposes mentioned in this Privacy Policy and the applicable Terms of Use:</p>
                    <p className='pText'>Government institutions/authorities to the extent required:</p>
                    <ul>
                        <ol>
                            <li>Under the laws, rules, and regulations and/or under orders of any relevant judicial or quasi-judicial authority;</li>
                            <li>To protect and defend the rights or property of Clash;</li>
                            <li>To fight fraud and credit risk;</li>
                            <li>To enforce the applicable Terms of Use (to which this Privacy Policy is also a part); or</li>
                            <li>When Clash, in its sole discretion, deems it necessary in order to protect its rights or the rights of others.</li>
                        </ol>
                    </ul>

                    <p className='pText'>If otherwise required by an order under any law for the time being in force including in response to inquiries by Government agencies for the purpose of verification of identity, or for prevention, detection, investigation including cyber incidents, prosecution, and punishment of offenses.</p>

                    <p className='pText'>Clash makes all User’s Personal Information accessible to its employees and data processors only on a need-to-know basis. All the employees and data processors, who have access to, and are associated with the processing of User Information, are obliged to respect its confidentiality.</p>

                    <p className='pText'>Non-personally identifiable information may be disclosed to third party ad servers, ad agencies, technology vendors and research firms to serve advertisements to the Users. Clash may also share its aggregate findings (not specific information) based on information relating to the User’s internet use to prospective investors, strategic partners, sponsors and others in order to help growth of Clash’s business.</p>

                    <p className='pText'>We may also disclose or transfer the User’s Personal Information, to another third party as part of reorganization or a sale of the assets or business of Clash. Any third party to which Clash transfers or sells its assets will have the right to continue to use the Personal Information and/or other information that a User provides to us.</p>

                </div>


                <div className="mb-4">
                    <h2 className="subHeading">Links to Third-Party</h2>
                    <p className='pText'>The links to third-party advertisements, third party websites or any third party electronic communication services (referred to as “Third Party Links”) may be provided on the Website which are operated by third parties and are not controlled by, or affiliated to, or associated with Clash unless expressly specified on the Website. If you access any such Third Party Links, we request you review the Third Party’s privacy policy. We are not responsible for the policies or practices of Third Party Links.</p>
                </div>


                <div className="mb-4">
                    <h2 className="subHeading">Security Practices and Procedures</h2>
                    <p className='pText'>Clash adopts reasonable security practices and procedures to include technical, operational, managerial and physical security control measures in order to protect the Personal Information in its possession from loss, misuse and unauthorized access, disclosure, alteration and destruction.</p>
                    <p className='pText'>You hereby acknowledge that Clash is not responsible for any intercepted information sent via the internet, and you hereby release us from any and all claims arising out of or related to the use of intercepted information in any unauthorized manner.</p>
                </div>

                <div className="mb-4">
                    <h2 className="subHeading">Retention</h2>
                    <p className='pText'>We have personal information retention processes designed to retain Users’ Personal Information for no longer than necessary for the purpose for which it was collected or provided to us or to otherwise meet legal requirements.</p>
                </div>

                <div className="mb-4">
                    <h2 className="subHeading">User’s rights in relation to their personal information collected by Clash</h2>
                    <p className='pText'>All the information provided to Clash by a User, including Sensitive Personal Data or Information, is voluntary. User has the right to withdraw her/ his /its consent at any time, in accordance with the terms of this Privacy Policy and the applicable Terms of Use, but please note that withdrawal of consent will not be retroactive.</p>
                    <p className='pText'>Users can access, modify, correct and delete the Personal Information provided by them which has been voluntarily given by the User and collected by Clash in accordance with this Privacy Policy and the applicable Terms of Use. However, if the User updates her/ his/ its information, Clash may keep a copy of the information which User originally provided to Clash in its archives for User documented herein. In case the User seeks to update or correct her/ his/ its Personal Information, the User may exercise these rights by emailing Clash at <a href="mailto:connect@getclash.com">connect@getclash.com</a> and communicating the change(s) to be updated on Clash’s records.</p>
                    <p className='pText'>In case the User does not provide her/ his/ its information or consent for usage of Personal Information or subsequently withdraws her/ his/ its consent for usage of the Personal Information so collected, Clash reserves the right to discontinue the Services for which the said information was sought.</p>
                </div>

                <div className="mb-4">
                    <h2 className="subHeading">Complaints and Grievance Redressal</h2>
                    <p className='pText'>Any complaints, abuse, or concerns with regards to content and or comment or breach of these terms shall be immediately informed to Clash’s Grievance Officer via email, signed with an electronic/ digital signature on <a href="mailto:connect@getclash.com">connect@getclash.com</a>.</p>
                </div>
                <div className="mb-4">
                    <h2 className="subHeading">Change to this Privacy Policy</h2>
                    <p className='pText'>This Privacy Policy may be updated periodically to reflect changes to comply by applicable privacy laws. The revised Privacy Policy will be posted on this Website. We strongly encourage you to please refer to this Privacy Policy often for the latest information about our personal information practices.</p>
                </div>

                <div>
                    <h2 className="subHeading">Contact Us</h2>
                    <p className='pText'>Please contact us on <a href="mailto:connect@getclash.com">connect@getclash.com</a> with any questions or concerns that you may have about the Privacy Policy or the manner in which your personal information is collected, used, disclosed and otherwise managed by Clash.</p>
                </div>
            </div>
        </div>
    )
}

export default CPrivacypolicy