import React, { useEffect, useState } from 'react'
import appstore from './../assests/webp/app_store.webp'
import playstore from './../assests/webp/play_store.webp'
import { isIOS, isAndroid, isDesktop } from 'react-device-detect'
import playstoreicon from './../assests/webp/playstore.webp'
import appstoreicon from './../assests/webp/appstore.webp'
import check from './../assests/Check-Mark.png'
import './deviceDetectionBtn.scss'
import axios from 'axios'

const emailRegex = /^[a-zA-Z0-9+.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,3}$/
function DeveiceDetectionBtn() {
    const [details, setDetails] = useState({});
    const [email, setEmail] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [uniqueText, setUniqueText] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        fetch(
            "https://get.geojs.io/v1/ip/country.json"
        )
            .then(response => response.json())
            .then(data => setDetails(data.name));
    }, []);

    // console.log(details);

    // useEffect(() => {
    //     const loadScriptByURL = (id, url, callback) => {
    //         const isScriptExist = document.getElementById(id);
    //         if (!isScriptExist) {
    //             let script = document.createElement('script');
    //             script.type = 'text/javascript';
    //             script.src = url;
    //             script.id = id;
    //             script.onload = function () {
    //                 if (callback) { callback(); }
    //             };
    //             document.body.appendChild(script);
    //         }
    //         if (isScriptExist && callback) { callback(); }
    //     };

    //     // load the script by passing the URL
    //     loadScriptByURL('recaptcha-key', 'https://www.google.com/recaptcha/api.js?render=6LeWmXsjAAAAABhWPSTJJbUd1kMjNDJ9NjrQmm71', function () {
    //     });
    // }, []);


    const handleSubmit = () => {
        if (emailRegex.test(email)) {
            setError('')
            // (window).grecaptcha.ready(() => {
            //     (window).grecaptcha.execute('6LeWmXsjAAAAABhWPSTJJbUd1kMjNDJ9NjrQmm71', { action: 'submit' }).then(token => {
            //         console.log(token);
            //         Token = token
            //     });
            // });
            try {
                axios.post('https://dev.yesgnome.com/dashboard-api/register',
                    { 'country': details, 'email': email, 'platform': '' })
                    .then((res) => {
                        console.log('resss----', res)
                        if (res.status === 200) {
                            setSubmitted(true)
                        } else {
                            setSubmitted(false)
                        }
                    })
                    .catch((err) => {
                        console.log('error-----::', err?.response?.data);
                        setUniqueText(err?.response?.data.data || 'Something went wrong!. Please try later');
                    })
            } catch (error) {
                console.log('tryCatch_error-----::', error);
            }
        }
        else {
            setError('Enter Valid Email')
        }
    }

    const resetData = () => {
        setEmail('')
        setSubmitted(false)
        setError('')
        setUniqueText('')
    }

    return (
        <>
            <div>
                {
                    details === 'Philippines' ?
                        <>
                            {
                                isAndroid ?
                                    <a href="https://play.google.com/store/apps/details?id=com.clash.club" className='downloadBtn' target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <div className="bg-white">
                                            <div className="appStoreSection d-flex align-items-center justify-content-start">
                                                <div>
                                                    <img src={playstore} alt="play store" />
                                                    <img src={appstore} alt="app store" className='ms-2' />
                                                </div>
                                                <label className="mb-0 ms-2 ms-md-2 ms-lg-2 ms-xl-3 text-white">Download Now!</label>
                                            </div>
                                        </div>
                                    </a> :
                                    ''
                            }
                            {
                                isIOS ?
                                    <a href="https://apps.apple.com/app/clash-skill-games/id1631335355" target='_blank' className='downloadBtn' rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <div className="bg-white">
                                            <div className="appStoreSection d-flex align-items-center justify-content-start">
                                                <div>
                                                    <img src={playstore} alt="play store" />
                                                    <img src={appstore} alt="app store" className='ms-2' />
                                                </div>
                                                <label className="mb-0 ms-2 ms-md-2 ms-lg-2 ms-xl-3 text-white" style={{ marginTop: '2px' }}>Download Now!</label>
                                            </div>
                                        </div>
                                    </a> :
                                    ''
                            }
                            {
                                isDesktop ?
                                    <>
                                        <div data-bs-toggle="modal" data-bs-target="#staticBackdropLink" className='downloadBtn'>
                                            <div className="bg-white">
                                                <div className="appStoreSection d-flex align-items-center justify-content-start">
                                                    <div className=''>
                                                        <img src={playstore} alt="play store" />
                                                        <img src={appstore} alt="app store" className='ms-2' />
                                                    </div>
                                                    <label className="mb-0 ms-2 ms-md-2 ms-lg-2 ms-xl-3 text-white">Download Now!</label>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ''
                            }
                        </> :
                        <>
                            <div className='downloadBtn'>
                                <div className="bg-white">
                                    <div className="appStoreSection d-flex align-items-center justify-content-start" data-bs-toggle="modal" data-bs-target="#staticBackdropSub">
                                        <div className='d-block d-md-block'>
                                            <img src={playstore} alt="play store" />
                                            <img src={appstore} alt="app store" className='ms-2' />
                                        </div>
                                        <label className="mb-0 text-white ms-2 ms-md-3">Download Now!</label>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
            <div className="modal fade downloadBtnPopup" id="staticBackdropLink" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabelLink" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ width: '480px' }}>
                        <div className="modal-body pt-0">
                            <div className="modal-header mb-3">
                                <h4 className='text-center'>Download Now!</h4>
                                <svg xmlns="http://www.w3.org/2000/svg" data-bs-dismiss="modal" aria-label="Close" width="22" height="22" stroke='#8645f8' fill="#8645f8" className="bi bi-x-lg" viewBox="0 0 16 16" style={{ top: '6px' }}>
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg>
                            </div>
                            <div className='playIcons d-flex align-items-center justify-content-center gap-3 mt-4'>
                                <a href="https://play.google.com/store/apps/details?id=com.clash.club" target='_blank' rel="noopener noreferrer" className='hvr-push'>
                                    <img src={playstoreicon} alt="playstore" />
                                </a>
                                <a href="https://apps.apple.com/app/clash-skill-games/id1631335355" target='_blank' rel="noopener noreferrer" className='hvr-push'>
                                    <img src={appstoreicon} alt="app store" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade downloadBtnPopup" id="staticBackdropSub" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabelSub" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {
                            !submitted ?
                                <>
                                    <div className="modal-header">
                                        <h4 className='text-center'>Oh! That's a Bummer</h4>
                                        <svg xmlns="http://www.w3.org/2000/svg" data-bs-dismiss="modal" aria-label="Close" width="22" height="22" stroke='#8645f8' fill="#8645f8" className="bi bi-x-lg" viewBox="0 0 16 16" onClick={() => resetData()}>
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        </svg>
                                    </div>
                                    <div className="modal-body">
                                        <p className='text-center mb-3'>We haven't crashed into your country as yet. Leave us your email ID & you will hear it from first.</p>
                                        {/* <p className='text-center'></p> */}
                                        <div className='playIcons d-flex flex-column align-items-center justify-content-center'>
                                            <input type="email" className='form-control' required value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                            {error !== '' && <small className='mb-0 mt-2 text-danger'>{error}</small>}
                                            {uniqueText !== '' && <p className='mt-2 text-danger'>{uniqueText}</p>}
                                            <button type="submit" onClick={() => { handleSubmit() }}>Submit</button>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="modal-header pt-2">
                                        <h4 className='text-center'>Congratulations!</h4>
                                        <svg xmlns="http://www.w3.org/2000/svg" data-bs-dismiss="modal" aria-label="Close" width="22" height="22" stroke='#8645f8' fill="#8645f8" className="bi bi-x-lg" viewBox="0 0 16 16" onClick={() => resetData()} style={{ top: '25px' }}>
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        </svg>
                                    </div>
                                    <div className="modal-body pb-4 pt-0">
                                        <div className='text-center'>
                                            <img src={check} alt="check" className='my-4' style={{ width: '70px' }} />
                                            <p className='fw-bold mb-0 pb-2 px-md-5'>We have received your email. We will get back to you soon. </p>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeveiceDetectionBtn
