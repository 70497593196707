import React from 'react'
import logo from './../assests/Clash-Logo.png'
import { HashLink as Link } from 'react-router-hash-link';
import menu from './../assests/menu.png'
import symbol from './../assests/Clash-Symbol.png'
// import Socialmedia from '../socialmedia/socialmedia'
import SideMenu from '../sidemenu/sidemenu'
import DeveiceDetectionBtn from '../deviceDetectionBtn/deveiceDetectionBtn'
import './header.scss'
// import AOS from 'aos';
import headerImage from "./heroImage.json";
import Lottie from "lottie-react";

function HeaderNew() {

    const header = () => {
        return (
            <>
                {/* {
                    console.log(process.env.PUBLIC_URL)
                } */}
                <div className="container header px-lg-2 px-xl-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <Link smooth to="/">
                                <img className="logo" src={logo} alt="logo" style={{ marginLeft: '-5px' }} />
                            </Link>
                            <div className="d-none d-md-block">
                                <div className='d-flex navLinks'>
                                    <Link smooth to="/#about"><p className='mb-1 ms-6'>About</p></Link>
                                    <Link smooth to="/#featuredgames"><p className='mb-1 ms-6'>Featured Games</p></Link>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center litepaperMenu">
                            {/* <a href="https://litepaper.clash.club/clash-litepaper/" target='_blank' rel="noopener noreferrer" className="litePaper">Litepaper</a> */}
                            <img src={menu} alt="menu icon" className='hamburgerMenu d-block d-md-none' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" width="26" />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="position-relative headerMain">
                <div className="gradient-background"></div>
                <img src={symbol} alt="symbol" className='symbol d-none d-md-block' />
                <section className='position-absolute w-100 main'>
                    {header()}
                    <div className="container px-lg-0" data-aos="fade-up" data-aos-offset="200">
                        <div className="heroSection position-relative">
                            <div className="text-white mt-xxl-2 py-md-4 py-lg-5">
                                {/* <div className="d-block d-md-block" data-aos="fade-up" data-aos-duration="500"> */}
                                <h1 className="mainTitle text-center text-md-start mt-4 mt-md-0 ">
                                    Big Rewards<br /> Play Multiple Games
                                </h1>
                                <div>
                                    <div className="d-none d-md-block">
                                        <p className="signupTitle mb-0 text-center text-md-start">Signup Now and Win Amazing Rewards</p>
                                        {/* <p className="usdc text-center text-md-start">USDC & CLH Tokens</p> */}
                                    </div>
                                    <div className="d-block d-md-none">
                                        <p className="signupTitle mb-0 text-center text-md-start mb-4">Signup Now & Win Amazing Rewards</p>
                                        {/* <p className="usdc text-center text-md-start">USDC and CLH Tokens</p> */}
                                    </div>
                                </div>
                                {/* </div> */}
                                <div className='d-block d-md-none text-center mx-auto'>
                                    <Lottie animationData={headerImage} loop={true} style={{ width: '75%', margin: 'auto' }} />
                                </div>
                                <DeveiceDetectionBtn />
                                {/* <Socialmedia /> */}
                            </div>
                            <div className="text-center px-0 px-md-2">
                                <div className="d-none d-md-block">
                                    <div>
                                        <Lottie animationData={headerImage} loop={true} style={{ width: '95%' }} />
                                    </div>
                                </div>
                                <div id='featuredgames'></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="mobileSideMenu offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <SideMenu />
                </div>
            </div>
        </>
    )
}

export default HeaderNew