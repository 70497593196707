import React from 'react'
import './policies.scss'

function CClashtokens() {
    return (
        <div className="policies">
            <div className="container my-4">
                <h1 className='mainheading'>How to get Clash Tokens</h1>
                <p className='lastUpdate mb-5'><b>Last Updated:</b> September 10, 2022</p>
                <p className='pText'>Clash Tokens (CLH) are used for playing skill games against relevant opponents.</p>
                <p className='pText'>CLH is on Solana Blockchain.</p>
                <h4 className='subHeading mt-3'>There are multiple ways to get CLH tokens.</h4>
                <div>
                    <ul>
                        <li className='mb-2'>Buy CLH tokens | On ramping
                            <ol>
                                <li>Swap other currency to Clash on any of the available on ramping solutions.</li>
                            </ol>
                        </li>
                        <li className='mb-2'>Win in 1v1 battles
                            <ol>
                                <li>By winning 1v1 battles, players can earn CLH tokens.</li>
                            </ol>
                        </li>
                        <li className='mb-2'>Rewards
                            <ol>
                                <li>Players playing 1v1 &amp; tournaments can get additional CLH tokens everyday</li>
                                <li>Complete every checkpoint in each of the following categories &amp; get access to amazing rewards daily.
                                    <ul>
                                        <li>Number of games</li>
                                        <li>Time Spent</li>
                                        <li>Amount Wagered</li>
                                        <li>Win Streak</li>
                                        <li>Daily Streak</li>
                                        <li>Staking Reward</li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li className='mb-2'>Win in tournaments
                            <ol><li> By winning in free &amp; paid tournaments, players can get CLH tokens.</li>
                                <li>All the players who are on top of the leaderboard are eligible to get CLH tokens.</li>
                            </ol>
                        </li>
                        <li>Refer &amp; Invite friends
                            <ol><li>Players can refer &amp; invite their friends on Clash to get CLH tokens.</li>
                                <li>Players get rewarded for every valid transaction their direct referrals or indirect referrals make on Clash.</li>
                            </ol>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CClashtokens