import React from 'react'
import Footer from './footer/footer'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import archery from './assests/webp/games/Archery.jpg'
import bighunter from './assests/webp/games/BigHUnter.jpg'
import cricket from './assests/webp/games/Cricket.jpg'
import dominoes from './assests/webp/games/Dominoes.jpg'
import magicpoker from './assests/webp/games/MagicPoker.jpg'
import poolplay from './assests/webp/games/Pool-Paly.jpg'
import solitaire from './assests/webp/games/Solitaire.jpg'
import speed from './assests/webp/games/Speed-21.jpg'
import WWTBAM from './assests/webp/games/WWTBAM.jpg'
import GinRummy from './assests/webp/games/Gin-Rummy.jpg'
import Hoops from './assests/webp/games/Hoops.jpg'
import Poker from './assests/webp/games/Poker_Video.jpg'
import BubbleShooter from './assests/webp/games/Bubble-Shooter.jpg'
import Bingo from './assests/webp/games/Bingo.jpg'
import Rummy from './assests/webp/games/rummygame.png'
// our promises

import wallet from './assests/webp/Engaging-&-Entertaining-Games.png'
import safeSecure from './assests/webp/Safe-&-Secure.png'
import rewards from './assests/webp/Skill-Building.png'
import games from './assests/webp/Community-of-Gamers.png'
import aboutVideo from './assests/about.mp4'

// import wallet from './assests/webp/Engaging-&-Entertaining-Games.png'
// import safeSecure from './assests/webp/Safe-&-Secure.png'
// import rewards from './assests/webp/Skill-Building.png'
// import games from './assests/webp/Community-of-Gamers.png'

import HeaderNew from './header/header'
import './ourpromise.scss'
import './featuredgames.scss'

function Home() {

    const width = '100%'
    const featuredGames = () => {
        return (
            <>
                <div className="featureGamesGrid">
                    <LazyLoadImage src={poolplay} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={speed} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={bighunter} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={archery} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={cricket} alt="Featured Games" width={width} loading="lazy" />
                </div>
                <div className="featureGamesGrid mt-3" data-aos='fade-up'>
                    <LazyLoadImage src={solitaire} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={magicpoker} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={GinRummy} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={dominoes} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={WWTBAM} alt="Featured Games" width={width} loading="lazy" />
                </div>
                <div className="featureGamesGrid mt-3" data-aos='fade-up'>
                    <LazyLoadImage src={Hoops} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={Poker} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={BubbleShooter} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={Bingo} alt="Featured Games" width={width} loading="lazy" />
                    <LazyLoadImage src={Rummy} alt="Featured Games" width={width} loading="lazy" />
                </div>
            </>
        )
    }


    return (
        <>
            <HeaderNew />
            <section className='d-none d-md-block'>
                <div className="container featuredGames">
                    <div className="d-none d-md-block">
                        <h2 className='subTitleMain'>Featured Games</h2>
                    </div>
                    <div className='d-none d-md-block'>
                        {featuredGames()}
                    </div>
                </div>
            </section>
            <section className="promise d-none d-md-block">
                <div className="container px-0">
                    <h2 className='subTitleMain'>Our Promise</h2>
                    <div className="desktopPromises d-grid gap-4 gap-lg-4 gap-xl-4 gap-xxl-4">
                        <div className="promiseCard text-center">
                            <LazyLoadImage src={wallet} alt="clash wallet" loading="lazy" />
                            <h5 className='mt-3 mt-lg-3 mt-xl-4 mt-xxl-5'>Engaging & <br />Entertaining Games</h5>
                        </div>
                        <div className="promiseCard text-center">
                            <LazyLoadImage src={safeSecure} alt="clash crypto" loading="lazy" />
                            <h5 className='mt-3 mt-lg-3 mt-xl-4 mt-xxl-5'>Safe and Secure <br />Environment</h5>
                        </div>
                        <div className="promiseCard text-center">
                            <LazyLoadImage src={games} alt="clash games" loading="lazy" />
                            <h5 className='mt-3 mt-lg-3 mt-xl-4 mt-xxl-5'>Community of <br />Passionate Gamers</h5>
                        </div>
                        <div className="promiseCard text-center">
                            <LazyLoadImage src={rewards} alt="clash rewards" loading="lazy" />
                            <h5 className='mt-3 mt-lg-3 mt-xl-4 mt-xxl-5'>Skill-Building <br />Opportunities</h5>
                        </div>
                    </div>
                </div>
                <div id='about'></div>
            </section>
            <section className="container aboutSection d-none d-md-block" style={{ padding: '40px 0' }}>
                <div className="row">
                    <div className="" data-aos="fade-up">
                        <h2 className='subTitleMain mb-3'>About</h2>
                    </div>
                    <div className="col-lg-8 pe-xxl-0">
                        <div className="col-md-12 ps-0">
                            {/* <ul data-aos="fade-up"> */}
                            <p className='mt-2' data-aos="fade-up">
                                Clash is a mobile gaming platform that offers skill-based gaming. Players can enjoy over 30+ games of different genres and compete against real players across the World. Whether it's a 1-on-1 challenge or a multiplayer tournament, Clash matches players with similar skill levels for each game.
                            </p>
                            {/* </ul> */}
                        </div>
                        <div className="col-md-8 text-center mx-auto d-block d-lg-none" data-aos="fade-left" data-aos-offset="400">
                            <video width={"100%"} preload="none" muted autoPlay loop playsInline>
                                <source src={aboutVideo} type="video/mp4" />
                            </video>
                        </div>
                        <div className="col-md-12 ps-0">
                            <p className='fw-bold' data-aos="fade-up">
                                Clash provides
                            </p>
                            <ul data-aos="fade-up">
                                <li className='mt-2 mt-md-3'>
                                    <b>Global access:</b> Players worldwide can join the fun & play against each other creating a more diverse and engaging community.
                                </li>
                                <li className='mt-2 mt-md-3'>
                                    <b>Trust and fairness:</b>  Clash eliminates the risk of bots and cheating and ensures a fair and transparent gaming experience.
                                    For more inquiries & support, contact
                                </li>
                            </ul>

                            <ul data-aos="fade-up">
                                <li className='mt-2 mt-md-3'>
                                    For more inquiries & support, contact <br />
                                    <a href="mailto:support@getclash.com" style={{ color: '#c328fb' }}>support@getclash.com</a>.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 d-none d-lg-block text-end pe-xxl-0" style={{ marginTop: '-10px', marginLeft: '-20px' }} data-aos="fade-left" data-aos-offset="400">
                        <video width={"100%"} preload="none" muted autoPlay loop playsInline>
                            <source src={aboutVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Home